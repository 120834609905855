<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { contractor_id: contractor_id, id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${loading_contractor ? '' : `${contractor.name} | `}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      type="text"
                      dense
                      :rules="rules.required"
                      maxlength="50"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Apellido paterno*"
                      v-model="data.first_surname"
                      type="text"
                      dense
                      :rules="rules.required"
                      maxlength="25"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Apellido materno"
                      v-model="data.second_surname"
                      :rules="rules.text25"
                      type="text"
                      dense
                      maxlength="25"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <DatePicker
                      label="Fecha de nacimiento*"
                      :model.sync="data.birthday"
                      clean
                      all_date
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Aseguradora*"
                      v-model="data.insurance_id"
                      :rules="rules.required"
                      :items="insurances"
                      item-value="id"
                      :item-text="(item) => item.name"
                      :loading="insurances_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-select
                      label="Tipo de asegurado*"
                      v-model="data.insured_type_id"
                      :rules="rules.required"
                      :items="insured_types"
                      item-value="id"
                      :item-text="(item) => item.insured_type"
                      :loading="insured_types_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Categoría"
                      v-model="data.insured_category_id"
                      :items="insured_categories"
                      item-value="id"
                      :item-text="(item) => item.name"
                      :loading="insured_categories_ldg"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="ID SM*"
                      v-model="data.enrollment"
                      type="text"
                      dense
                      :rules="rules.required"
                      maxlength="10"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Póliza*"
                      v-model="data.policy"
                      type="text"
                      dense
                      :rules="rules.required"
                      maxlength="30"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Número de asegurado*"
                      v-model="data.credential"
                      type="text"
                      dense
                      :rules="rules.required"
                      maxlength="20"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="8">
                    <v-text-field
                      label="Nombre de oficina"
                      v-model="data.office_address"
                      :rules="rules.text100"
                      type="text"
                      dense
                      maxlength="100"
                      counter
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <DatePicker
                      label="Vigencia*"
                      :model.sync="data.validity"
                      clean
                      all_date
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Estado"
                      v-model="data.entity_id"
                      :items="entities"
                      item-value="id"
                      :item-text="(item) => item.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities()"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Municipio"
                      v-model="data.municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(item) => item.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="RFC"
                      v-model="data.code_tax"
                      :rules="rules.code_tax"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Suma potenciada"
                      v-model="data.powered_amount"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Suma asegurada"
                      v-model="data.assured_amount"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <DatePicker
                      label="Antigüedad"
                      :model.sync="data.antiquity"
                      clean
                      all_date
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Usuario sistema'"
                      v-model="data.user.active"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      date_picker_birthday: false,
      date_picker_vality: false,
      page_route: "asegurados",
      id: this.$route.params.id ? this.$route.params.id : 0,
      contractor_id: this.$route.params.contractor_id,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        policy: "",
        credential: "",
        office_address: "",
        enrollment: "",
        validity: "",
        birthday: "",
        contractor_id: null,
        insurance_id: null,
        insured_type_id: null,
        user: {
          id: null,
          active: false,
          email: "",
        },
        municipality_id: null,
        entity_id: null,
        code_tax: "",
        powered_amount: "0",
        assured_amount: "0",
        antiquity: "",
        insured_category_id: null,
      },
      contractor: {},
      loading_contractor: true,
      rules: {},
      insured_types: [],
      insured_types_loading: true,
      insurances: [],
      insurances_loading: true,
      municipalities: [],
      municipalities_loading: false,
      entities: [],
      entities_loading: true,
      insured_categories: [],
      insured_categories_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      let valid_user = true;

      if (
        this.data.user.active &&
        (this.data.enrollment == "" || !this.data.enrollment)
      ) {
        valid_user = false;
      }

      if (valid_user) {
        if (this.$refs.form_data.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;

                this.data.user.email = this.data.credential;

                let obj = Object.assign({}, this.data);
                obj.user = JSON.stringify(obj.user);

                storeUpdate(
                  "insureds",
                  this.login.token,
                  this.id,
                  obj,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  response.success
                    ? this.$router.push({
                        name: `${this.page_route}${
                          this.store_mode ? "" : ".view"
                        }`,
                        params: { id: this.id },
                      })
                    : console.log(response.message);
                });

                this.loading = false;
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Si se activa Usuario sistema el ID SM es obligatorio"
          )
        );
      }
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index("municipalities/" + this.data.entity_id, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.municipalities = response.data;
        this.municipalities_loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.data.contractor_id = this.contractor_id;
    show("contractors", this.login.token, this.contractor_id).then(
      (response) => {
        this.contractor = response;
        this.loading_contractor = false;
      }
    );

    catalog("insured_types", this.login.token).then((response) => {
      this.insured_types = response;
      this.insured_types_loading = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities_loading = false;
    });

    catalog("insured_categories", this.login.token).then((res) => {
      this.insured_categories = res;
      this.insured_categories_ldg = false;
    });

    index("insurances", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((response) => {
      this.insurances = response.data;
      this.insurances_loading = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("insureds", this.login.token, this.id).then((response) => {
        this.data = response;
        this.getMunicipalities();
        this.loading = false;
      });
    }
    this.rules = rules();
  },
};
</script>